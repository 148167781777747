.auth-page-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  > img {
    width: 140px;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  > app-auth-step-indicator {
    flex-grow: 1;
    justify-content: center;
    margin-left: -140px;
    margin-bottom: 10px;
  }
}

.auth-content {
  display: flex;
  height: 100%;
  padding: 40px 56px;
  flex-direction: column;
  flex: 1 0 51%;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  background: radial-gradient(50% 50% at 50% 50%, rgba(251, 210, 162, 0.25) 0%, rgba(251, 210, 162, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(31, 233, 255, 0.25) 0%, rgba(31, 233, 255, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, rgba(32, 254, 165, 0.25) 0%, rgba(32, 254, 165, 0) 100%);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position:
    0 -450px,
    -640px 452px,
    640px 452px;
}

.auth-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > .form-group,
  .form-row,
  .form-check {
    width: 100%;
  }

  h1 {
    font-family: 'Rebond Grotesque';
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #171e25;
    margin-bottom: 8px;
    text-align: center;
  }

  h3 {
    color: $cello;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  h3:last-of-type {
    margin-bottom: 40px;
  }

  .submit-btn {
    margin-top: 32px;
  }

  .submit-btn-wide {
    max-width: 440px;
    width: calc(100% - 30px);
  }
}

.auth-side-content {
  display: flex;
  flex: 1 1 49%;
  background-image: url('../../assets/svg/auth/login-background.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    display: none;
  }

  > div {
    width: 320px;
    flex-grow: 1;
    font-size: 30px;
    line-height: 41px;
    color: $white;
    text-align: center;
  }

  > img {
    margin: 20px;
  }
}

.auth-separator {
  display: flex;
  align-items: center;
  max-width: 440px;
  width: 100%;
  margin: 33px 0 23px;

  > div {
    flex: 1 1;
    height: 1px;
    background: $pattens-blue;
  }

  > span {
    flex: 0 0;
    margin: 0 23px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $heather;
  }
}

.auth-google-btn {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  > img {
    margin-right: 15px;
  }

  > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
}
