body,
html {
  font-family: 'Muli', serif;
}

body {
  height: 100%;
  width: 100%;
}

h1 {
  font-size: 26px;
  line-height: 38px;
  margin-bottom: 3px;
  color: $black;
  font-weight: 600;
}

h2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  color: $midnight;
  margin: 0;
}

h3 {
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  color: $black;
  margin: 0;
}

h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $black;
  margin: 0;
}

p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

a {
  color: $blue;

  &:hover {
    color: $blue;
    text-decoration: none !important;
  }

  &:disabled {
    pointer-events: none;
  }
}

strong {
  font-weight: 600;
}

label {
  display: flex;
  align-items: center;
  color: #252b2e;
  position: relative;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;

  app-tooltip {
    margin-left: 10px;
  }

  &.required {
    padding-left: 10px;

    &::before {
      content: '*';
      color: $error;
      top: 0;
      left: 0;
      position: absolute;
    }
  }
}

input,
select,
textarea {
  display: block;
  width: 100%;
  height: 48px;
  font-weight: 500;
  border: 1px solid $pattens-blue;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  padding: 13px 16px;
  outline: none;

  &:focus {
    border-color: $focus;
  }

  &::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    height: 48px;
    color: $heather;
  }

  &[disabled] {
    background: $alice-blue;
    color: $heather;
  }
}

select {
  position: relative;
  appearance: none;
  padding-right: 30px !important;
  background-image: url('../assets/svg/common/arrow-down-blue.svg') !important;
  background-position-x: calc(100% - 10px) !important;
  background-position-y: calc(50%) !important;
  background-repeat: no-repeat !important;
  background-size: 10px 5px !important;

  option {
    display: block;
    height: 48px;

    &:focus {
      background: $blue;
    }
  }
}

.focusable {
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.keyboard-mode .focusable:focus {
  border-color: $focus;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.impersonation-notification {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-height: 32px;
  background: $green;

  > div {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    font-size: 12px;
    line-height: 18px;
    margin-left: 150px;
    color: $white;

    > img {
      margin-right: 8px;
    }

    span {
      font-weight: 700;
    }
  }

  > button {
    color: white;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 14px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }
}

.label-grey {
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
  color: $heather;
}

.BeaconFabButtonFrame {
  visibility: hidden;
}

.BeaconContainer {
  top: 49px;
}

body:has(.impersonation-notification) {
  .BeaconContainer {
    top: 81px;
  }
}

.page-header {
  padding: 41px 45px 17px;
  background: $alice-blue;
  width: fit-content;
  min-width: 100%;
}

.billing-notification {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 200;
  display: flex;
  align-items: center;
  max-width: 535px;
  height: 45px;
  margin-top: 4px;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;

  &.expiring {
    background-color: rgba(30, 162, 226, 0.1);
    border-color: $blue;
  }

  &.expired {
    background-color: rgba(242, 34, 6, 0.1);
    border-color: $red;
  }

  &.impersonation {
    top: 35px;
  }

  &__text {
    font-weight: 600;
    line-height: 20px;
    padding: 12px 20px;
  }

  &__button {
    &.expiring {
      background: #1ea2e2;
    }

    &.expired {
      background: #f22206;
    }
    color: white;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    height: 30px;
    width: 125px;
    border: 1px solid;
    border-radius: 8px;
  }

  &__dismiss {
    margin-left: 13px;
    font-weight: bold;
  }

  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
  }
}

.radio-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-left: 12px;
}
