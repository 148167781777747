.detailed-info {
  display: flex;
}

.detailed-info_columns {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  margin: -10px;
}

.detailed-info_column {
  flex: 1 0 180px;
  padding: 10px;

  > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

.detailed-info_detail-name {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: $heather;
}

.detailed-info_detail-value {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  min-height: 24px;
  word-break: break-word;
}
