.modal {
  margin: 0 15px;
  overflow: hidden !important;
  width: calc(100% - 30px);
}

ngb-modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

.modal-header {
  padding: 0 15px;
  height: 40px;
  border: none;
}

.modal-dialog {
  height: 100%;
  margin: 0 auto;
}

.modal-content button.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  > img {
    width: 18px;
  }
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 45px 55px;
  border-radius: 6px;
  background: $white;

  > h1 {
    text-align: center;
    margin-bottom: 16px;
  }

  > form {
    width: 100%;
    max-width: 440px;
  }

  .buttons-group {
    margin-top: 23px;
  }
}

.modal-content {
  max-height: calc(100% - 40px);
  border: none;
  margin: 0 auto;
  pointer-events: none;

  > * {
    pointer-events: all;
    position: relative;
    border-radius: 6px;
  }
}

.modal-footer {
  padding: 0 40px 24px;
  border: none;
}

.modal-sm {
  max-width: 600px;
}

.modal-xl,
.modal-lg {
  margin: auto !important;

  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    max-height: 95%;
    border-radius: 4px;
    overflow: hidden;

    > * {
      background: $white;
      max-height: 100%;
      max-width: 100%;
      overflow: auto;
    }
  }
}

.modal-xl {
  max-width: 1400px;
  .modal-content {
    width: 100%;
    flex-grow: 1;
  }
}

.modal-lg {
  max-width: 1024px;
  .modal-content {
    width: fit-content;
  }
}

.modal-sm {
  .modal-content {
    overflow: visible;
  }
}

/* fix for address input in a modal */
.pac-container {
  z-index: 1500;
}

.modal-background-transparent {
  .modal-content {
    background: transparent;
  }
}

.modal-content-overflow-visible {
  .modal-content {
    overflow: visible !important;
    > * {
      overflow: visible !important;
    }
  }
}
