.btn-loader {
  width: 10em;
  height: 10em;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 2px;
  background-image: url('../assets/svg/common/LS-Symbol-Loader-White.svg');
}

.typeahead-loader {
  position: absolute;
  right: 10px;
  top: 10px;

  > .loader {
    margin: 0;
    font-size: 2px;
  }
}
