button:focus {
  outline: none;
}

button[disabled] {
  opacity: 0.5;
  cursor: default;
}

.btn-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 205px;
  height: 40px;
  min-height: 40px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.submit-btn {
  @extend .btn-primary;

  background-color: $blue;
  border-color: $blue;
  color: $white;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $button-hover;
    border-color: $button-hover;
    color: $white;
  }

  &[disabled],
  &.disabled {
    opacity: 0.5;
    border: inherit;
    background-color: $blue;
    pointer-events: none;
  }
}

.warning-btn {
  @extend .btn-primary;

  background-color: $watermelon;
  border-color: $watermelon;
  color: $white;
}

.decline-btn {
  @extend .btn-primary;

  background-color: $white;
  color: $heather;
  border: 1px solid $heather;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $white !important;
    border-color: $button-hover !important;
    color: $button-hover !important;
  }

  &[disabled],
  &.disabled {
    background-color: $white !important;
    color: $heather !important;
    border: 1px solid $heather !important;
    opacity: 0.5;
  }

  .btn-loader {
    border-left-color: $blue !important;
  }
}

.decline-btn-grey {
  @extend .decline-btn;

  background-color: $white;
  color: $heather;
  border: 1px solid $heather;

  &[disabled] {
    background-color: $white !important;
    color: $heather !important;
    border: 1px solid $heather !important;
    opacity: 0.7;
  }

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      background-color: $white !important;
      color: $shuttle-grey !important;
      border: 1px solid $shuttle-grey !important;
    }
  }

  .btn-loader {
    background-image: url('../assets/svg/common/LS-Symbol-Loader.svg');
  }
}

.buttons-group {
  display: flex;
  justify-content: center;
  width: calc(100% + 30px);
  margin: -10px -15px;
  flex-wrap: wrap;

  button {
    flex-grow: 1;
    max-width: 205px;
    margin: 10px 15px;
  }
}

.secondary-btn {
  @extend .btn-primary;

  color: $blue;
  background-color: $background-light;
  border: none;

  &:hover {
    color: $blue;
    background-color: rgba(39, 159, 217, 0.2) !important;
  }

  &[disabled],
  &.disabled {
    background-color: $background-light !important;
    color: $blue;
  }

  .btn-loader {
    background-image: url('../assets/svg/common/LS-Symbol-Loader.svg');
  }
}

.btn-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $blue;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: $button-hover;
    text-decoration: none;
  }
}

.secondary-btn-white {
  @extend .secondary-btn;

  background-color: $white;
  border: 1px solid $blue;
}

.back-link-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  cursor: pointer;
  color: $blue !important;
  padding-left: 5px;

  &:hover,
  &:focus,
  &:active {
    color: $button-hover;
    text-decoration: none;
  }

  &::before {
    content: '';
    position: relative;
    left: -5px;
    top: auto;
    bottom: auto;
    width: 7px;
    height: 12px;
    background-image: url('../assets/svg/common/arrow-left-blue.svg');
  }
}
