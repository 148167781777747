form {
  position: relative;
}

.form-group {
  position: relative;
  margin-bottom: 28px;
  box-sizing: border-box;
  height: fit-content;

  bfv-messages {
    display: block;
    position: absolute;
  }

  > label {
    margin-bottom: 4px;
  }
}

.form-group.has-error {
  margin-bottom: 25px !important;

  input,
  select,
  textarea {
    border-color: $error;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }

  rich-text-editor,
  app-select .select-container {
    border-color: $error;
    &.focused {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
}

.form-row {
  display: flex;
  min-width: 175px;
  max-height: calc(100% - 30px);
  margin: 0 -15px;
  align-items: baseline;

  .form-group {
    flex: 1 1;
    padding: 0 15px;
  }
}

.form-group {
  min-width: 175px;
  max-width: 440px;
}

.form-check {
  display: flex;
  cursor: pointer;
  padding-left: 0;

  > input {
    display: none;
  }

  .form-check-label {
    position: relative;
    flex-grow: 1;
    line-height: 24px;
    padding-left: 30px;
    font-size: 16px;
    color: $cello;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $white;
    border: 1px solid $pattens-blue;
    border-radius: 4px;

    &:focus {
      border-color: $focus;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  .checkmark.checked {
    border-color: $green;
    background-image: url('../assets/svg/common/checkmark.svg');
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center;
  }
}

.form-control-error {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $error;
}

.country-code-control {
  flex: 0 0 150px !important;
  min-width: 132px;

  input::placeholder {
    color: transparent;
  }

  select {
    &.is-valid {
      background-image: none;
    }
  }
}

.custom-control-error {
  position: absolute;
  top: calc(100% + 5px);
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $error;
}
