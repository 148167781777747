.tooltip-icon {
  width: 16px;
  height: 16px;
  border: none;
  background: none;
  background-image: url('../assets/svg/common/info-icon.svg');
  background-position: center;
}

.tooltip-icon-blue {
  @extend .tooltip-icon;

  background-image: url('../assets/svg/common/tooltip-icon-blue.svg');
}
