@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cc-ellipsis-animation {
  width: fit-content;
  position: relative;

  &:after {
    content: '...';
    display: inline-block;
    position: absolute;
    right: 0;
    translate: 100% 0;
    animation: ellipsis 3s infinite;

    @keyframes ellipsis {
      0% {
        content: '...';
      }
      25% {
        content: '';
      }
      50% {
        content: '.';
      }
      75% {
        content: '..';
      }
      100% {
        content: '...';
      }
    }
  }
}
