.dropdown,
.dropup {
  display: inline-flex;
  align-items: center;
  height: 26px;

  > span::after {
    display: none;
  }
}

.dropdown-toggle {
  display: inline-flex;
  width: 26px;
  height: 22px;
  background-image: url('../assets/svg/common/dropdown-ellipsis.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  &:focus,
  &:hover {
    background-image: url('../assets/svg/common/dropdown-ellipsis-blue.svg');
  }

  &::after {
    display: none !important;
  }
}

.dropdown-menu {
  border: 1px solid $pattens-blue;
  box-shadow:
    0px 8px 18px rgba(0, 0, 0, 0.04),
    0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  max-height: 600px;
  overflow: auto;

  &.placement-body {
    margin-top: 50%;
    margin-right: 50%;
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  line-height: 17px;
  padding: 0 30px;
  height: 40px;

  &[disabled] {
    cursor: default;
    pointer-events: none;
    color: $shuttle-grey;
    opacity: 0.5;
  }

  &:active {
    background: $blue;
  }
}
