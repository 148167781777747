@import '_colors';

.x-hellosign-embedded--in-modal {
  z-index: 100;
}

.x-hellosign-embedded__modal-content {
  margin: 40px 0;
  top: 0 !important;
  height: calc(100% - 80px) !important;
  background-color: $white;
  border-radius: 4px;

  iframe {
    background-color: $white !important;
    margin: 53px 15px 15px;
    border: 1px solid $pattens-blue;
    min-width: calc(100% - 30px) !important;
    height: calc(100% - 68px) !important;
  }
}

.x-hellosign-embedded__modal-close {
  top: 5px !important;
  right: 5px !important;
}

.x-hellosign-embedded__modal-close-button {
  box-shadow: none !important;
  background: transparent !important;
  transition: none !important;

  &:hover {
    background: transparent !important;
  }

  &:before,
  &:after {
    background-color: $heather !important;
  }
}
