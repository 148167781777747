@font-face {
  font-family: Rebond Grotesque;
  src: url('../assets/fonts/rebond_grotesque.otf') format('opentype');
}

// Used for Microsoft button
@font-face {
  font-family: 'Segoe UI Regular';
  src:
    url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff2')
      format('woff2'),
    url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
