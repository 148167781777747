.f-bold {
  font-weight: 700;
}

.f-medium {
  font-weight: 500;
}

.f-cursive {
  font-style: italic;
}

.color-red {
  color: $watermelon;
}

.d-flex {
  display: flex;
  align-items: center;
}

.d-grid {
  display: grid;
}

.hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.t-align-center {
  text-align: center;
}

.max-size {
  height: 100%;
  width: 100%;
}

.w-fit-content {
  max-width: none;
  width: fit-content;
}
