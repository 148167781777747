.tabs {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  border-bottom: 1px solid $pattens-blue;
}

.tabs_tab {
  position: relative;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 8px;
  user-select: none;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &.active {
    color: $blue;
    font-weight: 500;
    padding-bottom: 4px;
    border-bottom: 4px solid $blue;
  }

  &.unread::after {
    content: '';
    position: absolute;
    right: -11px;
    top: 0;
    width: 8px;
    height: 8px;
    background-color: $watermelon;
    border-radius: 50%;
  }
}
