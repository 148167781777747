@import '_colors';

#fm-toolbar {
  display: flex;
  padding: 5px 25px !important;

  > div {
    position: relative !important;
  }
}

#fm-toolbar-wrapper #fm-toolbar li svg .fill-background {
  fill: #d2ecf9;
}

#fm-toolbar-wrapper #fm-toolbar li a:hover svg .fill-background,
#fm-toolbar-wrapper #fm-toolbar li li:hover > .fm-svg-icon > svg .fill-background {
  fill: $pattens-blue-light;
}

#fm-toolbar-wrapper #fm-toolbar li a:hover svg .fill-border,
#fm-toolbar-wrapper #fm-toolbar li li:hover > .fm-svg-icon > svg .fill-border {
  fill: $button-hover;
}

#fm-toolbar-wrapper #fm-toolbar li svg .fill-border {
  fill: $blue;
}

#fm-toolbar-wrapper #fm-toolbar a > span {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $cello;
}

.fm-btn-open-fields {
  display: none !important;
}

// Hiding trial version watermarks, need to remove after buying license
.fm-branding-bar {
  display: none !important;
}
//

#fm-pivot-view .fm-grid-layout div.fm-cell {
  font-family: 'Muli' !important;
  font-weight: 600;
  border-color: $pattens-blue !important;
  background-color: #fff;
}

.fm-cell .fm-header {
  font-weight: 800 !important;
}

.fm-empty {
  background: $white !important;
}

.fm-cell.fm-header.fm-header-c.fm-v-sort {
  background: $white !important;
  font-size: 15px !important;
  line-height: 15px !important;
  padding: 8px 20px !important;

  .fm-icon::before {
    top: 8px !important;
  }
}

.fm-ui-container,
.fm-ui-popup {
  * {
    font-family: 'Muli' !important;
  }
}

#fm-pivot-view .fm-grid-layout .fm-filters .fm-filter-header {
  background: #eef1f6 !important;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
}

#fm-pivot-view .fm-grid-layout .fm-filters {
  background: #eef1f6 !important;
}

#fm-pivot-view .fm-grid-layout div.fm-sheet-header {
  background: $white !important;
}

.fm-filters.fm-cols-filter {
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;

  .fm-cell {
    background: #eef1f6 !important;
    padding-right: 35px !important;
  }
}

.fm-ui-btn {
  border-radius: 8px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  background-color: $white !important;
  color: $heather !important;
  border: 1px solid $heather !important;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $white !important;
    border-color: $button-hover !important;
    color: $button-hover !important;
  }

  &[disabled],
  &.disabled {
    background-color: $white !important;
    color: $heather !important;
    border: 1px solid $heather !important;
    opacity: 0.5;
  }

  .btn-loader {
    border-left-color: $blue !important;
  }
}

.fm-btn-add-measure {
  color: $midnight !important;
  border-color: $heather !important;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    color: $midnight !important;
    border-color: $heather !important;
  }
}

.fm-ui-btn-dark {
  border-radius: 8px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  background-color: $blue !important;
  border-color: $blue !important;
  color: $white !important;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $button-hover !important;
    border-color: $button-hover !important;
    color: $white !important;
  }

  &[disabled],
  &.disabled {
    opacity: 0.5 !important;
    border: inherit !important;
    background-color: $blue !important;
    pointer-events: none !important;
  }
}

.fm-checked .fm-icon-display {
  background-color: $blue !important;
  border-color: $blue !important;
}

.fm-calc-action {
  background: $midnight !important;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    background: $cello !important;
  }
}

.fm-radio-wrap label:after {
  left: 4px !important;
  background: $blue !important;
  border-color: $blue !important;
}

.fm-alert-icon {
  .fm-circle {
    border-color: $blue !important;
  }

  .fm-ui-icon {
    font-size: 50px !important;
    top: -39px !important;
    left: -34px !important;
  }
}

.fm-sheet-selection-canvas {
  background-color: $background-light !important;
}

#fm-toolbar-wrapper #fm-toolbar .fm-dropdown {
  top: 75px !important;
}

#fm-toolbar-wrapper div.fm-popup {
  position: fixed;
}
