$blue: #1ea2e2;
$persian-blue: #0b0f67;
$cello: #46505a;
$heather: #aab4be;
$white: #ffffff;
$midnight: #28323c;
$watermelon: #ff5e5f;
$pattens-blue: #dce6f0;
$pattens-blue-light: #d2ecf9;
$error: #ea292a;
$warning: #f2994a;
$success: #27ae60;
$solitude: #e7eefe;
$green: #06af67;
$focus: #80bdff;
$orange: #f75734;
$black: #000000;
$lime-green: #22bc29;
$alice-blue: #f7f9fc;
$shuttle-grey: #64696e;
$background-light: rgba(39, 159, 217, 0.1);
$background-green: rgba(39, 174, 96, 0.1);
$background-red: rgba(242, 34, 6, 0.1);
$button-hover: #0aa8f5;
$ultramarine: #3971fb;
$red: #ea4335;
