@font-face {
    font-family: "icons";
    src: url("./icons.eot?7aee8252ae132b046b12f34ef825fc05#iefix") format("embedded-opentype"),
url("./icons.woff2?7aee8252ae132b046b12f34ef825fc05") format("woff2"),
url("./icons.woff?7aee8252ae132b046b12f34ef825fc05") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-workflow:before {
    content: "\f101";
}
.icon-workflow-fill:before {
    content: "\f102";
}
.icon-window-maximize:before {
    content: "\f103";
}
.icon-warning-fill:before {
    content: "\f104";
}
.icon-users:before {
    content: "\f105";
}
.icon-user:before {
    content: "\f106";
}
.icon-user-circle:before {
    content: "\f107";
}
.icon-user-add:before {
    content: "\f108";
}
.icon-upload-file:before {
    content: "\f109";
}
.icon-unordered-list:before {
    content: "\f10a";
}
.icon-underline:before {
    content: "\f10b";
}
.icon-trash:before {
    content: "\f10c";
}
.icon-todo-list:before {
    content: "\f10d";
}
.icon-tasks:before {
    content: "\f10e";
}
.icon-tasks-fill:before {
    content: "\f10f";
}
.icon-task-step-started:before {
    content: "\f110";
}
.icon-task-started:before {
    content: "\f111";
}
.icon-task-reassigned:before {
    content: "\f112";
}
.icon-task-close:before {
    content: "\f113";
}
.icon-tag:before {
    content: "\f114";
}
.icon-tag-alt:before {
    content: "\f115";
}
.icon-table:before {
    content: "\f116";
}
.icon-table-up:before {
    content: "\f117";
}
.icon-table-minus:before {
    content: "\f118";
}
.icon-table-down:before {
    content: "\f119";
}
.icon-sync-error:before {
    content: "\f11a";
}
.icon-sync-auth-error:before {
    content: "\f11b";
}
.icon-switch-to-desktop:before {
    content: "\f11c";
}
.icon-suitcase:before {
    content: "\f11d";
}
.icon-success:before {
    content: "\f11e";
}
.icon-success-fill:before {
    content: "\f11f";
}
.icon-strikethrough:before {
    content: "\f120";
}
.icon-stocks:before {
    content: "\f121";
}
.icon-star:before {
    content: "\f122";
}
.icon-star-fill:before {
    content: "\f123";
}
.icon-split:before {
    content: "\f124";
}
.icon-spam:before {
    content: "\f125";
}
.icon-snooze:before {
    content: "\f126";
}
.icon-signature:before {
    content: "\f127";
}
.icon-show:before {
    content: "\f128";
}
.icon-settings:before {
    content: "\f129";
}
.icon-send-plane:before {
    content: "\f12a";
}
.icon-search:before {
    content: "\f12b";
}
.icon-row-remove:before {
    content: "\f12c";
}
.icon-row-insert-top:before {
    content: "\f12d";
}
.icon-row-insert-bottom:before {
    content: "\f12e";
}
.icon-right-small-fill:before {
    content: "\f12f";
}
.icon-retry:before {
    content: "\f130";
}
.icon-retry-upload:before {
    content: "\f131";
}
.icon-restart:before {
    content: "\f132";
}
.icon-resize-window:before {
    content: "\f133";
}
.icon-reports:before {
    content: "\f134";
}
.icon-reports-fill:before {
    content: "\f135";
}
.icon-reply:before {
    content: "\f136";
}
.icon-reply-all:before {
    content: "\f137";
}
.icon-reopen:before {
    content: "\f138";
}
.icon-remove-attachment:before {
    content: "\f139";
}
.icon-re-route:before {
    content: "\f13a";
}
.icon-quote:before {
    content: "\f13b";
}
.icon-products:before {
    content: "\f13c";
}
.icon-products-fill:before {
    content: "\f13d";
}
.icon-plus-circle:before {
    content: "\f13e";
}
.icon-plug-connected:before {
    content: "\f13f";
}
.icon-pencil-plus:before {
    content: "\f140";
}
.icon-padding-none:before {
    content: "\f141";
}
.icon-padding-all:before {
    content: "\f142";
}
.icon-ordered-list:before {
    content: "\f143";
}
.icon-open:before {
    content: "\f144";
}
.icon-notification:before {
    content: "\f145";
}
.icon-notification-up:before {
    content: "\f146";
}
.icon-none:before {
    content: "\f147";
}
.icon-no-connection-fill:before {
    content: "\f148";
}
.icon-network:before {
    content: "\f149";
}
.icon-network-fill:before {
    content: "\f14a";
}
.icon-move:before {
    content: "\f14b";
}
.icon-more:before {
    content: "\f14c";
}
.icon-minimize:before {
    content: "\f14d";
}
.icon-message-share:before {
    content: "\f14e";
}
.icon-merge:before {
    content: "\f14f";
}
.icon-merge-alt:before {
    content: "\f150";
}
.icon-maximize:before {
    content: "\f151";
}
.icon-manage:before {
    content: "\f152";
}
.icon-mailbox-open-outline:before {
    content: "\f153";
}
.icon-mail:before {
    content: "\f154";
}
.icon-mail-fill:before {
    content: "\f155";
}
.icon-locked:before {
    content: "\f156";
}
.icon-lock:before {
    content: "\f157";
}
.icon-location:before {
    content: "\f158";
}
.icon-link:before {
    content: "\f159";
}
.icon-italic:before {
    content: "\f15a";
}
.icon-invites:before {
    content: "\f15b";
}
.icon-internal-message:before {
    content: "\f15c";
}
.icon-intercom:before {
    content: "\f15d";
}
.icon-info:before {
    content: "\f15e";
}
.icon-info-fill:before {
    content: "\f15f";
}
.icon-inbox:before {
    content: "\f160";
}
.icon-important:before {
    content: "\f161";
}
.icon-import-product:before {
    content: "\f162";
}
.icon-home:before {
    content: "\f163";
}
.icon-home-fill:before {
    content: "\f164";
}
.icon-hide:before {
    content: "\f165";
}
.icon-help:before {
    content: "\f166";
}
.icon-hash:before {
    content: "\f167";
}
.icon-handle:before {
    content: "\f168";
}
.icon-forward:before {
    content: "\f169";
}
.icon-files:before {
    content: "\f16a";
}
.icon-files-fill:before {
    content: "\f16b";
}
.icon-external:before {
    content: "\f16c";
}
.icon-expand:before {
    content: "\f16d";
}
.icon-expand-alt:before {
    content: "\f16e";
}
.icon-expand-alt-2:before {
    content: "\f16f";
}
.icon-escape:before {
    content: "\f170";
}
.icon-error-fill:before {
    content: "\f171";
}
.icon-error-fill-alt:before {
    content: "\f172";
}
.icon-emoji:before {
    content: "\f173";
}
.icon-editor-attachment:before {
    content: "\f174";
}
.icon-edit:before {
    content: "\f175";
}
.icon-edit-pencil:before {
    content: "\f176";
}
.icon-dynamic-value:before {
    content: "\f177";
}
.icon-download:before {
    content: "\f178";
}
.icon-download-csv:before {
    content: "\f179";
}
.icon-down:before {
    content: "\f17a";
}
.icon-down-small-fill:before {
    content: "\f17b";
}
.icon-document:before {
    content: "\f17c";
}
.icon-document-alt:before {
    content: "\f17d";
}
.icon-delete:before {
    content: "\f17e";
}
.icon-create-task:before {
    content: "\f17f";
}
.icon-create-fill:before {
    content: "\f180";
}
.icon-copy:before {
    content: "\f181";
}
.icon-convos:before {
    content: "\f182";
}
.icon-convos-fill:before {
    content: "\f183";
}
.icon-company-matching:before {
    content: "\f184";
}
.icon-company-fill:before {
    content: "\f185";
}
.icon-column-remove:before {
    content: "\f186";
}
.icon-column-insert-right:before {
    content: "\f187";
}
.icon-column-insert-left:before {
    content: "\f188";
}
.icon-collapse:before {
    content: "\f189";
}
.icon-collapse-alt:before {
    content: "\f18a";
}
.icon-collapse-alt-2:before {
    content: "\f18b";
}
.icon-code:before {
    content: "\f18c";
}
.icon-clover:before {
    content: "\f18d";
}
.icon-close:before {
    content: "\f18e";
}
.icon-close-small:before {
    content: "\f18f";
}
.icon-clock:before {
    content: "\f190";
}
.icon-clock-fill:before {
    content: "\f191";
}
.icon-clear:before {
    content: "\f192";
}
.icon-chevronUp:before {
    content: "\f193";
}
.icon-chevronRight:before {
    content: "\f194";
}
.icon-chevronLeft:before {
    content: "\f195";
}
.icon-chevronDown:before {
    content: "\f196";
}
.icon-chevron-down-small:before {
    content: "\f197";
}
.icon-check:before {
    content: "\f198";
}
.icon-check-small:before {
    content: "\f199";
}
.icon-campaigns:before {
    content: "\f19a";
}
.icon-campaigns-fill:before {
    content: "\f19b";
}
.icon-calendar:before {
    content: "\f19c";
}
.icon-box:before {
    content: "\f19d";
}
.icon-border-none:before {
    content: "\f19e";
}
.icon-border-all:before {
    content: "\f19f";
}
.icon-bold:before {
    content: "\f1a0";
}
.icon-bag:before {
    content: "\f1a1";
}
.icon-attachment:before {
    content: "\f1a2";
}
.icon-attachment-alt:before {
    content: "\f1a3";
}
.icon-at:before {
    content: "\f1a4";
}
.icon-arrow-top-line:before {
    content: "\f1a5";
}
.icon-arrow-right-line:before {
    content: "\f1a6";
}
.icon-arrow-left-line:before {
    content: "\f1a7";
}
.icon-arrow-bottom-line:before {
    content: "\f1a8";
}
.icon-arrow-bottom-line-thin:before {
    content: "\f1a9";
}
.icon-all-mail:before {
    content: "\f1aa";
}
.icon-add:before {
    content: "\f1ab";
}
