.company-wiki-content-area__content-markdown,
.markdown-editor__preview-markdown,
.assistant-chat-message-content__body {
  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -1.2px;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.6px;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.3px;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.3px;
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.3px;
  }

  h6 {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.3px;
  }

  p,
  li,
  li::marker {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.3px;
  }

  a {
    color: var(--core-color-link, #0366d6);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    border-radius: var(--radium-md, 6px);
    max-width: 100%;
  }

  strong {
    font-weight: 600;
  }

  table {
    border-radius: var(--radium-md, 6px);
    outline: 1px solid var(--comp-row-color-border, #e8eaeb);
    outline-offset: -1px;
    font-family: Inter, sans-serif;
    font-style: normal;
    contain: paint;
    max-width: 100%;
    overflow-x: auto;

    thead {
      border-bottom: 1px solid var(--comp-row-color-border, #e8eaeb);
      background: var(--comp-row-color-bg, #fff);

      th {
        color: var(--comp-row-color-fg-medium, #565e66);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.24px;
        padding: 10px 12px;
        white-space: nowrap;
      }
    }

    tr {
      border-bottom: 1px solid var(--comp-row-color-border, #e8eaeb);
      background: var(--comp-row-color-bg, #fff);

      &:empty {
        display: none;
      }

      td {
        color: var(--comp-row-color-fg-strong, #25292e);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.42px;
        padding: 10px 12px;
        vertical-align: baseline;

        &:empty {
          display: none;
        }
      }
    }
  }
}
