/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 10:40:47 GMT
 */

:root {
  --comp-input-chip-color-bg: #eff0f1;
  --comp-input-chip-color-text: #25292E;
  --comp-input-chip-color-btn-enabled: #9099A3;
  --comp-input-chip-color-btn-hover: #4b545d;
  --comp-input-chip-color-border: #bfc7d0;
  --comp-input-color-bg: #fff;
  --comp-input-color-border: #bfc7d0;
  --comp-input-color-text: #25292E;
  --comp-input-color-fg-weak: #9099A3;
  --comp-input-color-icon: #4b545d;
  --comp-input-color-border-error: #ab2345;
  --comp-input-color-border-focus: #0588cc52;
  --comp-input-color-bg-disabled: #EDF1F5;
  --comp-input-color-fg-error: #ab2345;
  --comp-input-color-border-focused: #8dd2f4;
  --comp-input-color-fg-medium: #4b545d;
  --comp-label-color-text: #25292E;
  --comp-label-color-fg-weak: #9099A3;
  --comp-img-color-border: #10121414;
  --comp-tooltip-color-bg: #28323C;
  --comp-tooltip-color-text: #fff;
  --comp-menu-item-color-bg-hover: #25292e14;
  --comp-menu-item-color-bg-pressed: #25292e1a;
  --comp-menu-item-color-text: #25292E;
  --comp-menu-item-color-icon: #4b545d;
  --comp-menu-item-color-fg-weak: #9099A3;
  --comp-menu-color-bg: #fff;
  --comp-menu-color-border: #dde1e4;
  --comp-checkbox-color-bg: #fff;
  --comp-checkbox-color-border: #bfc7d0;
  --comp-checkbox-color-bg-selected: #08c;
  --comp-checkbox-color-fg-selected: #fff;
  --comp-checkbox-color-bg-selected-disabled: #a7c6e5;
  --comp-radiobutton-color-bg: #fff;
  --comp-radiobutton-color-border: #bfc7d0;
  --comp-radiobutton-color-bg-selected: #08c;
  --comp-radiobutton-color-fg-selected: #fff;
  --comp-radiobutton-color-bg-selected-disabled: #a7c6e5;
  --comp-toggle-color-bg: #D8DDE3;
  --comp-toggle-color-bg-selected: #08c;
  --comp-toggle-color-fg: #fff;
  --comp-toggle-color-bg-selected-disabled: #a7c6e5;
  --comp-toggle-color-border: #bfc7d0;
  --comp-toggle-color-border-active: #04689a;
  --comp-toggle-color-border-selected-disabled: #bfc7d0;
  --comp-toggle-color-fg-selected-disabled: #EDF1F5;
  --comp-subnav-link-color-bg-hover: #25292e14;
  --comp-subnav-link-color-bg-active: #04689a;
  --comp-subnav-link-color-text: #4b545d;
  --comp-subnav-link-color-text-on-active: #fff;
  --comp-subnav-link-color-icon: #4b545d;
  --comp-subnav-link-color-icon-on-active: #fff;
  --comp-nav-page-link-color-bg-hover: #37404a;
  --comp-nav-page-link-color-bg-active: #274760;
  --comp-nav-page-link-color-icon: #fff9;
  --comp-nav-page-link-color-icon-on-active: #fff;
  --comp-pill-sub-nav-color-bg: #25292e14;
  --comp-pill-sub-nav-color-bg-on-active: #fff3;
  --comp-pill-sub-nav-color-fg: #4b545d;
  --comp-pill-sub-nav-color-fg-on-active: #fff;
  --comp-pill-main-nav-color-bg: #08c;
  --comp-pill-main-nav-color-fg: #fff;
  --comp-pill-main-nav-color-border: #28323C;
  --comp-pill-main-nav-color-border-on-hover: #37404a;
  --comp-pill-main-nav-color-border-on-active: #274760;
  --comp-main-nav-color-bg: #28323C;
  --comp-main-nav-color-border: #3e4751;
  --comp-top-nav-color-bg: #FAFBFC;
  --comp-top-nav-color-border: #dde1e4;
  --comp-account-switch-color-bg: #e9eaeb;
  --comp-account-switch-color-bg-hover: #d4d5d6;
  --comp-account-switch-color-bg-pressed: #cfd0d1;
  --comp-account-switch-color-icon: #4b545d;
  --comp-button-color-primary-text: #fff;
  --comp-button-color-primary-icon: #fff;
  --comp-button-color-primary-bg-enabled: #08c;
  --comp-button-color-primary-bg-hover: #1377b1;
  --comp-button-color-primary-bg-pressed: #1771a8;
  --comp-button-color-primary-bg-disabled: #a7c6e5;
  --comp-button-color-primary-text-disabled: #fff;
  --comp-button-color-primary-icon-disabled: #fff;
  --comp-button-color-secondary-text: #25292E;
  --comp-button-color-secondary-icon: #25292E;
  --comp-button-color-secondary-bg-enabled: #fff;
  --comp-button-color-secondary-bg-hover: #f3f3f3;
  --comp-button-color-secondary-bg-pressed: #eee;
  --comp-button-color-secondary-border: #bfc7d0;
  --comp-button-color-secondary-text-disabled: #c1c5c9;
  --comp-button-color-secondary-icon-disabled: #c1c5c9;
  --comp-button-color-secondary-border-disabled: #e4e8ee;
  --comp-button-color-ghost-icon: #4b545d;
  --comp-button-color-ghost-icon-hover: #25292E;
  --comp-button-color-ghost-bg-hover: #25292e14;
  --comp-button-color-ghost-bg-pressed: #25292e1a;
  --comp-button-color-ghost-invert-icon: #fffc;
  --comp-button-color-ghost-invert-icon-hover: #fff;
  --comp-button-color-ghost-invert-bg-hover: #fff3;
  --comp-button-color-ghost-invert-bg-pressed: #ffffff52;
  --comp-button-color-danger-text: #fff;
  --comp-button-color-danger-icon: #fff;
  --comp-button-color-danger-bg-enabled: #ab2345;
  --comp-button-color-danger-bg-hover: #9c2240;
  --comp-button-color-danger-bg-pressed: #95223d;
  --comp-button-color-danger-bg-disabled: #daa3aa;
  --comp-button-color-danger-text-disabled: #fff;
  --comp-button-color-danger-icon-disabled: #fff;
  --core-color-interaction-primary: #08c;
  --core-color-interaction-primary-disabled: #a7c6e5;
  --core-color-fg-strong: #25292E;
  --core-color-fg-medium: #4b545d;
  --core-color-fg-weak: #9099A3;
  --core-color-selectable-dark: #25292E;
  --core-color-selectable-light: #fff;
  --core-color-bg-surface: #fff;
  --core-color-bg-surface-1: #FAFBFC;
  --core-color-bg-surface-2: #EDF1F5;
  --core-color-bg-surface-3: #28323C;
  --core-color-border-on-surface: #dde1e4;
  --core-color-border-on-surface-3: #3e4751;
  --core-color-border-forms: #bfc7d0;
  --core-color-border-focus: #0588cc52;
  --core-color-selected-primary: #04689a;
  --core-color-danger-strong: #ab2345;
  --core-color-danger-weak: #fbe4e9;
  --core-color-success-strong: #08a262;
  --core-color-success-weak: #d6f9ea;
  --btn-shadow: [object Object];
  --menu-shadow: [object Object];
  --tooltip-shadow: [object Object];
  --select-field-shadow: [object Object],[object Object];
  --selector-shadow: [object Object];
  --drunk-tank-pink-0: #fffeff;
  --drunk-tank-pink-100: #fefafe;
  --drunk-tank-pink-200: #fbedfc;
  --drunk-tank-pink-300: #f7d6f8;
  --drunk-tank-pink-400: #f0b6f2;
  --drunk-tank-pink-500: #e88ceb;
  --drunk-tank-pink-600: #dc53e0;
  --drunk-tank-pink-700: #ba15c0;
  --drunk-tank-pink-800: #800f84;
  --drunk-tank-pink-900: #470849;
  --mercury-0: #fdfdfd;
  --mercury-100: #FAFBFC;
  --mercury-200: #EDF1F5;
  --mercury-300: #D8DDE3;
  --mercury-400: #B3BDC7;
  --mercury-500: #9099A3;
  --mercury-600: #5E6A75;
  --mercury-700: #4b545d;
  --mercury-800: #25292E;
  --mercury-900: #101214;
  --underwater-fern-0: #fdfffe;
  --underwater-fern-100: #f5fdfa;
  --underwater-fern-200: #d6f9ea;
  --underwater-fern-300: #a0f0cf;
  --underwater-fern-400: #45e2a1;
  --underwater-fern-500: #0ac677;
  --underwater-fern-600: #08a262;
  --underwater-fern-700: #067b4b;
  --underwater-fern-800: #045433;
  --underwater-fern-900: #022e1c;
  --pervenche-0: #fdfeff;
  --pervenche-100: #f8fcfe;
  --pervenche-200: #e3f4fc;
  --pervenche-300: #bfe6f9;
  --pervenche-400: #8dd2f4;
  --pervenche-500: #4bb8ee;
  --pervenche-600: #08c;
  --pervenche-700: #04689a;
  --pervenche-800: #34444d;
  --pervenche-900: #28323C;
  --xanthous-0: #fffefd;
  --xanthous-100: #f9e8c0;
  --xanthous-200: #daa42a;
  --xanthous-300: #725616;
  --chin-chin-cherry-0: #fffefe;
  --chin-chin-cherry-100: #fbe4e9;
  --chin-chin-cherry-200: #ef92a9;
  --chin-chin-cherry-300: #ab2345;
  --line-heights-base: 20;
  --line-heights-equal-to-font-size: 100%;
  --line-heights-xsm: 16;
  --line-heights-sm: 18;
  --line-heights-md: 24;
  --font-size-xsm: 10;
  --font-size-sm: 12;
  --font-size-base: 14;
  --font-size-xmd: 18;
  --font-size-lg: 20;
  --font-size-mlg: 24;
  --font-size-md: 16;
  --letter-spacing-medium: -2%;
  --letter-spacing-weak: -1%;
  --letter-spacing-strong: -3%;
  --letter-spacing-expand: 5%;
  --paragraph-spacing-0: 0;
  --paragraph-spacing-1: 8;
  --paragraph-spacing-2: 10;
  --paragraph-spacing-3: 14;
  --paragraph-spacing-4: 16;
  --body-body-semi: [object Object];
  --body-body: [object Object];
  --body-body-medium: [object Object];
  --heading-h300: [object Object];
  --heading-h200: [object Object];
  --heading-h100: [object Object];
  --ui-caption: [object Object];
  --ui-overline: [object Object];
  --ui-label: [object Object];
  --ui-button-medium: [object Object];
  --ui-button: [object Object];
  --ui-caption-semi: [object Object];
  --ui-overline-semi: [object Object];
  --ui-pill: [object Object];
  --text-case-none: none;
  --text-case-uppercase: uppercase;
  --text-decoration-none: none;
  --paragraph-indent-0: 0px;
  --font-families-inter: Inter;
  --font-weights-medium: Medium;
  --font-weights-semi: Semi Bold;
  --font-weights-regular: Regular;
  --select-shadow: [object Object],[object Object],[object Object];
  --token-set-order-0: options;
  --token-set-order-1: semantic;
  --token-set-order-2: components/buttons;
  --token-set-order-3: components/nav;
  --token-set-order-4: components/selectors;
  --token-set-order-5: components/dropdown;
  --token-set-order-6: components/helpers;
  --token-set-order-7: components/avatar;
  --token-set-order-8: components/forms;
  --token-set-order-9: components/chips;
  --light: [object Object];
}
